import React from 'react'
import { createRoot } from 'react-dom/client'

import { setupAppHeightStyleListener } from '../utils/utils'
import { AppTop } from './appTop'

setupAppHeightStyleListener()

const container = document.querySelector('#app')
const root = createRoot(container!)
root.render(<AppTop />)
