import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useDimensions from 'react-cool-dimensions'

import { RootState, useAppDispatch } from './store/store'
import { thunkLoadPicIndex } from './store/thunks'
import { resetAppHeight } from './utils/utils'

export const PicGallery: FC = () => {
  const dispatch = useAppDispatch()

  const currPicIndex = useSelector((state: RootState) => state.pics.currPicIndex)
  const numPics = useSelector((state: RootState) => state.pics.pics.length)
  const currPicUrl = useSelector((state: RootState) =>
    state.pics.currPicIndex !== undefined ? state.pics.pics[state.pics.currPicIndex].url : undefined
  )
  const isLoadingAPic = useSelector((state: RootState) => state.pics.isLoadingAPic)

  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (currPicIndex === undefined && !isLoadingAPic && numPics > 0) {
      dispatch(thunkLoadPicIndex(0))
    }
  }, [currPicIndex, isLoadingAPic, numPics])

  const onPointerDown = () => {
    if (isLoadingAPic) {
      return
    }
    if (currPicIndex === undefined) {
      return
    }
    dispatch(thunkLoadPicIndex((currPicIndex + 1) % numPics))
  }

  const { observe } = useDimensions({
    onResize: ({ width, height }) => {
      const imgElement = imgRef.current
      if (!imgElement) {
        return
      }
      const sizePx = Math.min(width, height)
      imgElement.setAttribute('width', `${sizePx}`)
      imgElement.setAttribute('height', `${sizePx}`)
      // translate image to center it:
      const xDelta = width > height ? (width - height) / 2 : 0
      imgElement.style.setProperty('transform', `translate(${xDelta}px, 0px)`)
      resetAppHeight()
    }
  })

  function renderImage(): ReactNode {
    if (!currPicUrl || currPicIndex === undefined || numPics <= 0) {
      return null
    }
    return (
      <div className='grow' style={{ width: '100%', minHeight: '200px' }} ref={observe}>
        <img
          className={`absolute rounded-lg ${isLoadingAPic ? 'opacity-40' : 'cursor-pointer'}`}
          ref={imgRef}
          src={currPicUrl}
          alt={`Pic ${currPicIndex + 1} of ${numPics}`}
          draggable={false}
          onPointerDown={onPointerDown}
        />
        <div
          className='relative flex flex-col items-center'
          style={{
            width: '100%'
          }}
        >
          <div className='px-2 font-extrabold text-gray-400 bg-gray-800/75 rounded-b-lg whitespace-nowrap'>
            {currPicIndex + 1} / {numPics}
          </div>
        </div>
      </div>
    )
  }

  function maybeRenderLoadingOverlay(): ReactNode {
    if (!isLoadingAPic) {
      return null
    }
    return (
      <div className='absolute flex justify-center items-center' style={{ width: '100%', height: '100%' }}>
        <div className='animate-spin inline-block w-8 h-8 border-4 border-x-transparent rounded-full' role='status' />
      </div>
    )
  }

  return (
    <div className='flex flex-col grow items-center relative' style={{ width: '100%' }}>
      {renderImage()}
      {maybeRenderLoadingOverlay()}
    </div>
  )
}
