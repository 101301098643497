import React, { FC, useEffect, useRef, useState } from 'react'

const BLINK_INTERVAL_MS = 500

export const BlinkingCursor: FC = () => {
  const timerId = useRef<NodeJS.Timer | undefined>()

  const [isCursorVisible, setIsCursorVisible] = useState(true)

  useEffect(() => {
    timerId.current = setInterval(() => {
      setIsCursorVisible((currIsCursorVisible) => !currIsCursorVisible)
    }, BLINK_INTERVAL_MS)
    return () => {
      clearInterval(timerId.current)
    }
  }, [])

  if (!isCursorVisible) {
    return null
  }

  return (
    <div
      data-blinking-cursor
      className='bg-white absolute bottom-1'
      style={{
        width: '40px',
        minWidth: '40px',
        height: '5px',
        minHeight: '5px'
      }}
    />
  )
}
