import React, { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { hideModal } from '../store/slices/modalsSlice'
import { RootState, useAppDispatch } from '../store/store'
import { InfoModal } from './infoModal'
import { LoseModal } from './loseModal'
import { WinModal } from './winModal'

export const ModalContainer: FC = () => {
  const dispatch = useAppDispatch()

  const modalType = useSelector((state: RootState) => state.modals.currModalType)

  function getModalElement(): ReactElement {
    if (!modalType) {
      return <></>
    }
    switch (modalType.type) {
      case 'info': {
        return (
          <InfoModal
            onClickClose={() => {
              dispatch(hideModal())
            }}
          />
        )
      }
      case 'win': {
        return <WinModal />
      }
      case 'lose': {
        return <LoseModal />
      }
    }
  }

  return getModalElement()
}
