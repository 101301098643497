import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from './button'

import { MAX_GUESSES, WORD_LENGTH } from './constants'
import { RootState } from './store/store'
import { Guesses } from './types'

function getGuessGrid(guesses: Guesses, isGameWon: boolean, currGuessIndex: number, presentLetters: string[]): string {
  // if the game is list, currGuessIndex has been incremented past the addressable number of guesses, so make sure we don't go that far:
  const maxGuessIndex = Math.min(currGuessIndex, MAX_GUESSES - 1)

  function getSquare(guessIndex: number, letterIndex: number): string {
    const letter = guesses[guessIndex][letterIndex]
    if (!letter) {
      return '⬜'
    }
    if (isGameWon && maxGuessIndex === guessIndex) {
      return '🟩'
    }
    if (presentLetters.includes(letter)) {
      return '🟦'
    }
    return '⬜'
  }

  const guessRows: string[] = []
  for (let i = 0; i <= maxGuessIndex; i++) {
    const guessSquares: string[] = []
    for (let j = 0; j < WORD_LENGTH; j++) {
      guessSquares.push(getSquare(i, j))
    }
    guessRows.push(guessSquares.join(''))
  }
  return guessRows.join('\n')
}

function getShareText(
  puzzleNumber: number | undefined,
  guesses: Guesses,
  isGameDone: boolean,
  isGameWon: boolean,
  currGuessIndex: number,
  presentLetters: string[]
): string | undefined {
  if (!isGameDone) {
    return
  }

  function getTitle(): string {
    const titleParts: string[] = []
    titleParts.push('Bizarrdle')
    if (puzzleNumber !== undefined) {
      titleParts.push(`#${puzzleNumber}`)
    }
    if (!isGameWon) {
      titleParts.push(`X/${MAX_GUESSES}`)
    } else {
      titleParts.push(`${currGuessIndex + 1}/${MAX_GUESSES}`)
    }
    return titleParts.join(' ')
  }

  const title = getTitle()
  const guessGrid = getGuessGrid(guesses, isGameWon, currGuessIndex, presentLetters)

  return `${title}\n${guessGrid}\n\nhttps://www.bizarrdle.com/`
}

export const ShareButtonContainer: FC = () => {
  const puzzleNumber = useSelector((state: RootState) => state.puzzle.puzzleNumber)
  const guesses = useSelector((state: RootState) => state.puzzle.guesses)
  const isGameDone = useSelector((state: RootState) => state.game.gameMode === 'win' || state.game.gameMode === 'lose')
  const currGuessIndex = useSelector((state: RootState) => state.puzzle.currGuessIndex)
  const presentLetters = useSelector((state: RootState) => state.puzzle.presentLetters)
  const isGameWon = useSelector((state: RootState) => state.game.gameMode === 'win')

  const [isCopied, setIsCopied] = useState(false)

  const shareText = getShareText(puzzleNumber, guesses, isGameDone, isGameWon, currGuessIndex, presentLetters)

  if (!shareText) {
    return null
  }

  return (
    <Button
      label={isCopied ? 'COPIED!' : 'SHARE'}
      isInverted={isCopied}
      onClick={() => {
        const shareData = { text: shareText }
        if (!navigator.canShare || !navigator.canShare(shareData)) {
          navigator.clipboard.writeText(shareData.text)
          setIsCopied(true)
        } else {
          navigator.share(shareData)
        }
      }}
      isLarge={true}
    />
  )
}
