import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { MAX_GUESSES, WORD_LENGTH } from '../../constants'
import { SavedPuzzle } from '../../db'
import { ArtistStyle, Guesses, ValidAnswerLetters } from '../../types'
import { range } from '../../utils/utils'

export interface PuzzleState {
  puzzleNumber?: number
  guesses: Guesses
  currGuessIndex: number
  currLetterIndex: number
  validAnswersLetters?: ValidAnswerLetters[]
  guessedLetters: string[]
  presentLetters: string[]
  invalidGuessIndex?: number
  artistStyle?: ArtistStyle
}

const DEFAULT_PUZZLE_STATE: PuzzleState = {
  guesses: range(MAX_GUESSES).map((guessIndex) => range(WORD_LENGTH).map((letterIndex) => undefined)),
  currGuessIndex: 0,
  currLetterIndex: 0,
  guessedLetters: [],
  presentLetters: []
}

export const puzzleSlice = createSlice({
  name: 'puzzle',
  initialState: DEFAULT_PUZZLE_STATE,
  reducers: {
    updatePuzzleFromSaved: (state, action: PayloadAction<SavedPuzzle>) => {
      const {
        puzzleNumber,
        guesses,
        currGuessIndex,
        currLetterIndex,
        guessedLetters,
        invalidGuessIndex,
        presentLetters
      } = action.payload
      if (puzzleNumber !== state.puzzleNumber) {
        return
      }
      state.guesses = guesses
      state.currGuessIndex = currGuessIndex
      state.currLetterIndex = currLetterIndex
      state.guessedLetters = guessedLetters
      state.invalidGuessIndex = invalidGuessIndex
      state.presentLetters = presentLetters
    },
    setPuzzleNumber: (state, action: PayloadAction<number | undefined>) => {
      state.puzzleNumber = action.payload
    },
    setValidAnswers: (state, action: PayloadAction<string[]>) => {
      state.validAnswersLetters = action.payload.map((validAnswer) =>
        validAnswer.split('').map((letter) => letter.toUpperCase())
      )
    },
    addLetter: (state, action: PayloadAction<string>) => {
      if (state.currGuessIndex >= MAX_GUESSES) {
        return
      }
      if (state.currLetterIndex < 0) {
        return
      }
      if (state.currLetterIndex > WORD_LENGTH) {
        return
      }
      const letter = action.payload
      state.guesses[state.currGuessIndex][Math.min(state.currLetterIndex, WORD_LENGTH - 1)] = letter
      state.currLetterIndex = Math.min(WORD_LENGTH, state.currLetterIndex + 1)
    },
    deleteLetter: (state) => {
      const deleteIndex = Math.min(Math.max(0, state.currLetterIndex - 1), WORD_LENGTH - 1)
      state.guesses[state.currGuessIndex][deleteIndex] = undefined
      state.currLetterIndex = deleteIndex
      state.invalidGuessIndex = undefined
    },
    setGuessedLetters: (state, action: PayloadAction<string[]>) => {
      state.guessedLetters = action.payload
    },
    setPresentLetters: (state, action: PayloadAction<string[]>) => {
      state.presentLetters = action.payload
    },
    setInvalidGuessIndex: (state, action: PayloadAction<number>) => {
      state.invalidGuessIndex = action.payload
    },
    unsetInvalidGuessIndex: (state) => {
      state.invalidGuessIndex = undefined
    },
    goToNextGuess: (state) => {
      state.currGuessIndex++
      state.currLetterIndex = 0
      state.invalidGuessIndex = undefined
    },
    setArtistStyle: (state, action: PayloadAction<ArtistStyle | undefined>) => {
      state.artistStyle = action.payload
    }
  }
})

export const {
  updatePuzzleFromSaved,
  setPuzzleNumber,
  setValidAnswers,
  addLetter,
  deleteLetter,
  setGuessedLetters,
  setPresentLetters,
  setInvalidGuessIndex,
  unsetInvalidGuessIndex,
  goToNextGuess,
  setArtistStyle
} = puzzleSlice.actions

export default puzzleSlice.reducer
