import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Pic {
  url: string
  isLoaded?: boolean
}

interface PicsState {
  pics: Pic[]
  currPicIndex?: number
  isLoadingAPic: boolean
}

const DEFAULT_PICS_STATE: PicsState = {
  pics: [],
  isLoadingAPic: false
}

export const picsSlice = createSlice({
  name: 'pics',
  initialState: DEFAULT_PICS_STATE,
  reducers: {
    setPicUrls: (state, action: PayloadAction<string[]>) => {
      state.pics = action.payload.map((url) => ({ url }))
      state.currPicIndex = undefined
      state.isLoadingAPic = false
    },
    setCurrPicIndex: (state, action: PayloadAction<number>) => {
      state.currPicIndex = action.payload
    },
    setPicIndexAsLoaded: (state, action: PayloadAction<number>) => {
      state.pics[action.payload].isLoaded = true
    },
    setIsLoadingAPic: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAPic = action.payload
    }
  }
})

export const { setPicUrls, setCurrPicIndex, setPicIndexAsLoaded, setIsLoadingAPic } = picsSlice.actions

export default picsSlice.reducer
