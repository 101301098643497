import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { KeyboardButtonContainer } from './keyboardButtonContainer'
import { showModal } from '../store/slices/modalsSlice'
import { RootState, useAppDispatch } from '../store/store'
import { ResultsButtonContainer } from './resultsButtonContainer'

export const Header: FC = () => {
  const puzzleNumber = useSelector((state: RootState) => state.puzzle.puzzleNumber)

  const dispatch = useAppDispatch()

  const onClickInfo = () => {
    dispatch(showModal({ type: 'info' }))
  }

  return (
    <div className='flex flex-row flex-wrap justify-end items-center w-full bg-gray-700'>
      <div className='grow'>
        <span className='text-6xl font-bold px-2'>BIZARRDLE</span>
        {puzzleNumber !== undefined && <span className='text-5xl font-medium text-gray-500'>#{puzzleNumber}</span>}
      </div>
      <KeyboardButtonContainer />
      <ResultsButtonContainer />
      <button onClick={onClickInfo} aria-label='Show Help'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-20 h-20'>
          <path
            fillRule='evenodd'
            d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    </div>
  )
}
