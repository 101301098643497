import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isMobile } from 'react-device-detect'

import { GameMode } from '../../types'

interface GameState {
  gameMode: GameMode
  showKeyboard: boolean
}

const DEFAULT_GAME_STATE: GameState = {
  gameMode: 'init',
  showKeyboard: isMobile
}

export const gameSlice = createSlice({
  name: 'game',
  initialState: DEFAULT_GAME_STATE,
  reducers: {
    setGameMode: (state, action: PayloadAction<GameMode>) => {
      state.gameMode = action.payload
    },
    setShowKeyboard: (state, action: PayloadAction<boolean>) => {
      state.showKeyboard = action.payload
    }
  }
})

export const { setGameMode, setShowKeyboard } = gameSlice.actions

export default gameSlice.reducer
