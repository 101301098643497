import React, { FC } from 'react'
import useEventListener from '@use-it/event-listener'

import { MAX_GUESSES } from '../constants'
import { range, validateLetter } from '../utils/utils'
import { GuessDisplay } from './guessDisplay'
import { useAppDispatch } from '../store/store'
import { thunkAddLetter, thunkDeleteLetter, thunkSubmitGuess } from '../store/thunks'

export const GuessGridDisplay: FC = () => {
  const dispatch = useAppDispatch()

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.metaKey || event.altKey || event.ctrlKey) {
      return
    }

    if (event.key === 'Enter') {
      dispatch(thunkSubmitGuess())
      return
    }

    if (event.key === 'Backspace') {
      dispatch(thunkDeleteLetter())
      return
    }

    if (event.key.length > 1) {
      // ensure it is a printable character
      return
    }
    const validatedLetter = validateLetter(event.key)
    if (!validatedLetter) {
      return
    }
    dispatch(thunkAddLetter(validatedLetter))
  })

  return (
    <section aria-label='guesses'>
      <div className='flex flex-col gap-1 bg-gray-700 rounded-lg items-center'>
        {range(MAX_GUESSES).map((i) => (
          <div key={i} className='flex flex-col gap-1 items-center'>
            {i > 0 && (
              <div
                className='bg-gray-800'
                style={{
                  height: '3px',
                  minHeight: '3px',
                  width: '450px',
                  minWidth: '450px'
                }}
              />
            )}
            <GuessDisplay guessIndex={i} />
          </div>
        ))}
      </div>
    </section>
  )
}
