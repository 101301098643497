import Dexie, { Table } from 'dexie'
import { PuzzleState } from './store/slices/puzzleSlice'

export type GameResult = 'win' | 'lose' | undefined

export type SavedPuzzle = Pick<
  PuzzleState,
  | 'puzzleNumber'
  | 'guesses'
  | 'guessedLetters'
  | 'invalidGuessIndex'
  | 'currGuessIndex'
  | 'currLetterIndex'
  | 'presentLetters'
> & { gameResult: GameResult }

export class BizarrdleDexie extends Dexie {
  savedPuzzle!: Table<SavedPuzzle>

  constructor() {
    super('bizarrdleDb')
    this.version(1).stores({
      savedPuzzle: 'puzzleNumber'
    })
  }
}

export const db = new BizarrdleDexie()
