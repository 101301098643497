import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from './store/store'

export const ErrorDisplayContainer: FC = () => {
  const error = useSelector((state: RootState) => state.error.error)

  if (!error) {
    return null
  }

  return <div className='fixed top-0 bg-red-900 z-20 w-full text-center p-3 font-bold select-text'>{error}</div>
}
