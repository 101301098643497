import React, { FC } from 'react'

import { KeyboardButton } from './keyboardButton'
import { useAppDispatch } from '../store/store'
import { thunkAddLetter, thunkDeleteLetter, thunkSubmitGuess } from '../store/thunks'
import { validateLetter } from '../utils/utils'

const KEYBOARD_ROW_CLASSNAME = 'flex flex-row gap-1 w-full'

const TOP_ROW_LETTERS = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P']
const MIDDLE_ROW_LETTERS = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L']
const BOTTOM_ROW_LETTERS = ['Z', 'X', 'C', 'V', 'B', 'N', 'M']

export const KeyboardDisplay: FC = () => {
  const dispatch = useAppDispatch()

  const onEnter = () => {
    dispatch(thunkSubmitGuess())
  }

  const onPointerDownLetter = (letter: string) => {
    const validatedLetter = validateLetter(letter)
    if (!validatedLetter) {
      return
    }
    dispatch(thunkAddLetter(validatedLetter))
  }

  const onBackspace = () => {
    dispatch(thunkDeleteLetter())
  }

  return (
    <>
      <div className='flex flex-col gap-1 items-center' style={{ width: 'calc(100vw - 20px)' }}>
        <div className={KEYBOARD_ROW_CLASSNAME}>
          {TOP_ROW_LETTERS.map((letter) => (
            <KeyboardButton key={letter} label={letter} onPointerDown={() => onPointerDownLetter(letter)} />
          ))}
        </div>
        <div className={KEYBOARD_ROW_CLASSNAME} style={{ width: '90%' }}>
          {MIDDLE_ROW_LETTERS.map((letter) => (
            <KeyboardButton key={letter} label={letter} onPointerDown={() => onPointerDownLetter(letter)} />
          ))}
        </div>
        <div className={KEYBOARD_ROW_CLASSNAME}>
          <KeyboardButton label='ENTER' useSmallText={true} onPointerDown={onEnter} addEmphasis={true} />
          {BOTTOM_ROW_LETTERS.map((letter) => (
            <KeyboardButton key={letter} label={letter} onPointerDown={() => onPointerDownLetter(letter)} />
          ))}
          <KeyboardButton
            label={
              <div style={{ transform: 'scale(150%)' }}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-6 h-6'>
                  <path
                    fillRule='evenodd'
                    d='M2.515 10.674a1.875 1.875 0 000 2.652L8.89 19.7c.352.351.829.549 1.326.549H19.5a3 3 0 003-3V6.75a3 3 0 00-3-3h-9.284c-.497 0-.974.198-1.326.55l-6.375 6.374zM12.53 9.22a.75.75 0 10-1.06 1.06L13.19 12l-1.72 1.72a.75.75 0 101.06 1.06l1.72-1.72 1.72 1.72a.75.75 0 101.06-1.06L15.31 12l1.72-1.72a.75.75 0 10-1.06-1.06l-1.72 1.72-1.72-1.72z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
            }
            addEmphasis={true}
            onPointerDown={onBackspace}
            ariaLabel='BACKSPACE'
          />
        </div>
      </div>
    </>
  )
}
