import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'

import { ColoredGuessText } from '../coloredGuessText'
import { MAX_GUESSES } from '../constants'
import { Modal } from './modal'

interface Props {
  onClickClose: () => void
}

export const InfoModal: FC<Props> = ({ onClickClose }: Props) => {
  return (
    <Modal
      title='BIZARRDLE'
      body={
        <>
          <span className='text-gray-400 font-bold'>Daily puzzle game!</span>
          <br />
          <br />
          Find objects that are common to{' '}
          <strong>
            <em>at least</em>
          </strong>{' '}
          two different pictures. {isMobile ? 'Tap' : 'Click'} the pic to cycle through them. Take the first letter of
          each common object's name and make a 5-letter English word. You have {MAX_GUESSES} tries to guess the word.
          <br />
          <br />
          For example, if you see a{' '}
          <span className='text-gray-400 font-bold'>
            <span className='text-white'>P</span>ear
          </span>{' '}
          🍐 in two pictures and a{' '}
          <span className='text-gray-400 font-bold'>
            <span className='text-white'>D</span>og
          </span>{' '}
          🐶 in two pictures, the solution likely contains a <span className='text-white font-bold'>P</span> and a{' '}
          <span className='text-white font-bold'>D</span> (plus 3 other letters).
          <br />
          <br />
          Any guessed letter that is in the solution will be{' '}
          <ColoredGuessText label='BLUE' color='blue' isSegmented={false} isSelectable={true} />.{' '}
          <span className='text-gray-400 font-bold'>
            This does <em>not</em> mean the letter is in the right place.
          </span>{' '}
          Letters that aren't in the solution are{' '}
          <ColoredGuessText label='GRAY' color='gray' isSegmented={false} isSelectable={true} />.
          <br />
          <br />
          <div className='bg-gray-900 p-2 rounded-lg'>
            <span className='text-gray-400 font-bold'>Hints:</span>
            <ul className='list-disc list-inside'>
              <li>
                Objects are kinda specific. If you see a cat 🐱 in two pictures, it's probably C for "cat", not A for
                "animal", which is too vague. Likewise, if you see a truck 🚚 in two pictures, it's probably T for
                "truck", not V for "vehicle".
              </li>
              <li>
                Objects are entire things. If you see a car 🚗 in two pictures, it's probably C for "car", not T for
                "tire" or D for "door" or any other car part.
              </li>
              <li>
                Objects are physical, tangible things, not abstract or subjective concepts. No descriptors or colors or
                emotions or materials are part of the solution.
              </li>
            </ul>
          </div>
          <br />
          By{' '}
          <a href='https://www.scottbalay.com/' target='_blank'>
            Scott Balay
          </a>{' '}
          &copy;{new Date().getFullYear()}
        </>
      }
      modalButtons={[{ label: 'Play', onClick: onClickClose }]}
      onClickClose={onClickClose}
    />
  )
}
