import React, { FC, useEffect } from 'react'
import { useOrientation } from 'react-use'

import { GuessGridDisplay } from '../guesses/guessGridDisplay'
import { Header } from '../header/header'
import { ModalContainer } from '../modals/modalContainer'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../store/store'
import { thunkFetchPuzzle } from '../store/thunks'
import { ErrorDisplayContainer } from '../errorDisplayContainer'
import { GameMode } from '../types'
import { PicGallery } from '../picGallery'
import { KeyboardDisplayContainer } from '../keyboard/keyboardDisplayContainer'
import { AlertDisplayContainer } from '../alertDisplayContainer'

const GAME_CONTENTS_SHOWN_IN_MODE: Record<GameMode, boolean> = {
  init: false,
  loading: false,
  active: true,
  win: true,
  lose: true
}

export const App: FC = () => {
  const dispatch = useAppDispatch()

  const { angle: orientationAngle } = useOrientation()

  const gameMode = useSelector((state: RootState) => state.game.gameMode)
  const hasValidAnswers = useSelector((state: RootState) => !!state.puzzle.validAnswersLetters?.length)

  useEffect(() => {
    dispatch(thunkFetchPuzzle())
  }, [])

  if (orientationAngle !== 0 && orientationAngle !== 180) {
    return (
      <div className='flex flex-col items-center justify-center text-3xl' style={{ height: '100vh' }}>
        Please rotate device.
      </div>
    )
  }

  const showGameContents = hasValidAnswers && GAME_CONTENTS_SHOWN_IN_MODE[gameMode]

  return (
    <div className='flex flex-col gap-5 items-center select-none' style={{ minHeight: 'var(--app-height)' }}>
      <Header />
      {showGameContents && (
        <>
          <GuessGridDisplay />
          <PicGallery />
          <KeyboardDisplayContainer />
        </>
      )}
      <ModalContainer />
      <ErrorDisplayContainer />
      <AlertDisplayContainer />
    </div>
  )
}
