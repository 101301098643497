import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../store/store'

interface Props {
  label: ReactNode
  ariaLabel?: string
  useSmallText?: boolean
  addEmphasis?: boolean
  onPointerDown: () => void
}

export const KeyboardButton: FC<Props> = ({ label, ariaLabel, useSmallText, addEmphasis, onPointerDown }: Props) => {
  const ariaLabelToSet = ariaLabel || (typeof label === 'string' ? label : undefined)

  const isPresentLetter = useSelector(
    (state: RootState) => typeof label === 'string' && state.puzzle.presentLetters.includes(label)
  )
  const isWrongLetter = useSelector(
    (state: RootState) =>
      typeof label === 'string' &&
      state.puzzle.guessedLetters.includes(label) &&
      !state.puzzle.presentLetters.includes(label)
  )

  function getDecorationClassName() {
    if (isPresentLetter) {
      return 'text-white bg-sky-900 hover:bg-sky-800'
    }
    if (isWrongLetter) {
      return 'text-gray-500 bg-gray-700 hover:bg-gray-600'
    }
    return `text-white ${addEmphasis ? 'bg-gray-700 hover:bg-gray-600' : 'bg-black hover:bg-gray-900'}`
  }

  const textClassName = useSmallText ? 'text-3xl' : 'text-5xl'

  return (
    <button
      className={`flex flex-col items-center justify-center rounded-lg text-center w-full active:bg-white active:text-black ${
        useSmallText ? 'p-2' : 'p-5'
      } ${textClassName} ${getDecorationClassName()}`}
      onClick={onPointerDown}
      aria-label={ariaLabelToSet}
    >
      {label}
    </button>
  )
}
