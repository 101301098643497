import { InboundPuzzle } from '../types'
import { delay, isLocalhost, isTestEnvironment } from '../utils/utils'

export const LOCALHOST_API_URL = 'http://localhost:3000/'

export function getFetchPuzzleUrl(): string {
  const baseUrl = isLocalhost() ? LOCALHOST_API_URL : 'https://api.bizarrdle.com/'
  if (isTestEnvironment()) {
    return `${baseUrl}?fetchTestPuzzle`
  }
  return baseUrl
}

export async function fetchPuzzle(): Promise<InboundPuzzle> {
  if (isLocalhost()) {
    // add an artificial delay on localhost to make loading clearly visible
    await delay(500)
  }

  const response = await fetch(getFetchPuzzleUrl())

  if (!response.ok) {
    return Promise.reject(response.status)
  }

  const json = await response.json()
  return json
}
