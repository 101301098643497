import React, { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import { HeaderButton } from './headerButton'
import { setShowKeyboard } from '../store/slices/gameSlice'
import { RootState, useAppDispatch } from '../store/store'

export const KeyboardButtonContainer: FC = () => {
  const dispatch = useAppDispatch()

  const showKeyboard = useSelector((state: RootState) => state.game.showKeyboard)

  if (isMobile) {
    return null
  }

  return (
    <HeaderButton
      label={`${showKeyboard ? 'Hide' : 'Show'} Keyboard`}
      onClick={() => {
        dispatch(setShowKeyboard(!showKeyboard))
      }}
    />
  )
}
