export function validateLetter(inputLetter: string): string | undefined {
  if (!inputLetter) {
    return
  }
  inputLetter = inputLetter.substring(0, 1).toUpperCase()
  if (/^[A-Z]$/.test(inputLetter)) {
    return inputLetter
  }
}

// Adapted from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore to avoid lodash.
export function range(length: number): number[] {
  return Array.from({ length }, (_, i) => i)
}

export function isLocalhost(): boolean {
  return window.location.href.toLowerCase().includes('localhost')
}

export function isTestEnvironment(): boolean {
  if (!isLocalhost()) {
    return false
  }
  const params = new URLSearchParams(window.location.search)
  return params.has('test')
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export function getBaseAbsolutePath() {
  if (window.location.href.toLowerCase().includes('github.io')) {
    return '/bizarrdle'
  }
  return '/'
}

export function resetAppHeight() {
  const doc = document.documentElement
  const newHeight = window.innerHeight
  doc.style.setProperty('--app-height', `${newHeight}px`)
}

// Adapted from https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
export function setupAppHeightStyleListener(): void {
  window.addEventListener('resize', resetAppHeight)
  resetAppHeight()
}
