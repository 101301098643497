import React, { FC } from 'react'

import { WORD_LENGTH } from '../constants'
import { GuessLetterDisplay } from './guessLetterDisplay'
import { range } from '../utils/utils'

interface Props {
  guessIndex: number
}

export const GuessDisplay: FC<Props> = ({ guessIndex }: Props) => {
  return (
    <div className='flex flex-row gap-3 p-1 rounded-xl'>
      {range(WORD_LENGTH).map((i) => (
        <GuessLetterDisplay guessIndex={guessIndex} letterIndex={i} key={i} />
      ))}
    </div>
  )
}
