import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import puzzleReducer from './slices/puzzleSlice'
import picsReducer from './slices/picsSlice'
import modalsReducer from './slices/modalsSlice'
import gameReducer from './slices/gameSlice'
import errorReducer from './slices/errorSlice'

export const store = configureStore({
  reducer: { puzzle: puzzleReducer, pics: picsReducer, modals: modalsReducer, game: gameReducer, error: errorReducer }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
