import React, { FC } from 'react'

import { GUESS_LETTER_MODE_CLASS_NAMES } from './guesses/guessLetterDisplay'

const SIZE = 30

interface Props {
  label: string
  color: 'red' | 'gray' | 'blue' | 'green'
  isLarge?: boolean
  isSegmented: boolean
  isSelectable: boolean
}

const classNames = {
  red: `${GUESS_LETTER_MODE_CLASS_NAMES.invalid} border-red-600 border-2`,
  gray: GUESS_LETTER_MODE_CLASS_NAMES.absent,
  blue: GUESS_LETTER_MODE_CLASS_NAMES.present,
  green: GUESS_LETTER_MODE_CLASS_NAMES.winning
}

export const ColoredGuessText: FC<Props> = ({ label, color, isLarge, isSegmented, isSelectable }: Props) => {
  if (isSegmented) {
    return (
      <span
        className={`flex flex-row inline-flex gap-1 whitespace-nowrap font-bold ${isLarge ? 'text-5xl' : ''} ${
          !isSelectable ? 'select-none' : ''
        }`}
      >
        {label.split('').map((letter, i) => (
          <span
            key={i}
            className={`flex flex-col items-center justify-center rounded ${classNames[color]}`}
            style={{
              padding: '30px',
              width: `${SIZE}px`,
              maxWidth: `${SIZE}px`,
              height: `${SIZE}px`,
              maxHeight: `${SIZE}px`
            }}
          >
            {letter}
          </span>
        ))}
      </span>
    )
  }
  return (
    <span
      className={`p-1 rounded font-bold whitespace-nowrap ${isLarge ? 'text-6xl' : ''} ${
        !isSelectable ? 'select-none' : ''
      } ${classNames[color]}`}
    >
      {label}
    </span>
  )
}
