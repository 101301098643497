import React, { FC } from 'react'
import { Provider } from 'react-redux'

import { store } from '../store/store'
import { isLocalhost } from '../utils/utils'
import { App } from './app'

export const AppTop: FC = () => {
  const params = new URLSearchParams(window.location.search)
  if (!isLocalhost() && !params.has('shhh')) {
    return <div>Bizarrdle is coming soon.</div>
  }

  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
