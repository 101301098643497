import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../store/store'
import { KeyboardDisplay } from './keyboardDisplay'

export const KeyboardDisplayContainer: FC = () => {
  const showKeyboard = useSelector((state: RootState) => state.game.showKeyboard)

  if (!showKeyboard) {
    return null
  }

  return <KeyboardDisplay />
}
