import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InfoModalType {
  type: 'info'
}

interface WinModalType {
  type: 'win'
}

interface LoseModalType {
  type: 'lose'
}

type ModalType = InfoModalType | WinModalType | LoseModalType

interface ModalsState {
  currModalType: ModalType | undefined
}

const DEFAULT_MODALS_STATE: ModalsState = {
  currModalType: { type: 'info' }
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: DEFAULT_MODALS_STATE,
  reducers: {
    showModal: (state, action: PayloadAction<ModalType>) => {
      state.currModalType = action.payload
    },
    hideModal: (state) => {
      state.currModalType = undefined
    }
  }
})

export const { showModal, hideModal } = modalsSlice.actions

export default modalsSlice.reducer
