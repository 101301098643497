import React, { FC } from 'react'

interface Props {
  label: string
  onClick: () => void
  isLarge: boolean
  isInverted: boolean
}

export const Button: FC<Props> = ({ label, onClick, isLarge, isInverted }: Props) => {
  const baseClassName = `p-3 rounded-lg border-2 border-indigo-300 font-bold ${isLarge ? 'text-6xl' : ''}`

  return (
    <button
      className={`${baseClassName} ${
        isInverted
          ? 'bg-indigo-300 text-black hover:bg-indigo-300 hover:border-indigo-300'
          : 'bg-indigo-900 text-indigo-300 hover:text-white hover:border-white'
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}
