import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AlertType = 'invalid-word'

export interface Alert {
  type: AlertType
  text: string
}

interface GameState {
  error?: string
  alert?: Alert
}

const DEFAULT_ERROR_STATE: GameState = {}

export const errorSlice = createSlice({
  name: 'error',
  initialState: DEFAULT_ERROR_STATE,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload
    },
    setAlert: (state, action: PayloadAction<Alert>) => {
      state.alert = action.payload
    },
    removeAlert: (state) => {
      delete state.alert
    },
    removeAlertOfType: (state, action: PayloadAction<AlertType>) => {
      if (state.alert?.type === action.payload) {
        delete state.alert
      }
    }
  }
})

export const { setError, setAlert, removeAlert, removeAlertOfType } = errorSlice.actions

export default errorSlice.reducer
