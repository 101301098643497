import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store/store'

export const ArtistStyleDisplay: FC = () => {
  const artistStyle = useSelector((state: RootState) => state.puzzle.artistStyle)

  if (!artistStyle) {
    return null
  }

  return (
    <div>
      This puzzle's pictures were generated{' '}
      <strong>
        <em>in the style of</em>
      </strong>{' '}
      <a href={artistStyle.artistUrl} target='_blank'>
        {artistStyle.artistName}
      </a>
      .
    </div>
  )
}
