import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchPuzzle } from '../api/api'
import { MAX_GUESSES, WORD_LENGTH } from '../constants'
import { Guess, InboundPuzzle } from '../types'
import { delay, isLocalhost } from '../utils/utils'
import { ALLOWED_WORDS } from '../words/allowedWords'
import { ANSWER_WORDS } from '../words/answerWords'
import { removeAlertOfType, setAlert, setError } from './slices/errorSlice'
import { setGameMode } from './slices/gameSlice'
import {
  addLetter,
  deleteLetter,
  goToNextGuess,
  setArtistStyle,
  setGuessedLetters,
  setInvalidGuessIndex,
  setPresentLetters,
  setPuzzleNumber,
  setValidAnswers,
  unsetInvalidGuessIndex,
  updatePuzzleFromSaved
} from './slices/puzzleSlice'
import { showModal } from './slices/modalsSlice'
import { setCurrPicIndex, setIsLoadingAPic, setPicIndexAsLoaded, setPicUrls } from './slices/picsSlice'
import { RootState } from './store'
import { db, GameResult } from '../db'

function isString(value: string | undefined): value is string {
  return value !== undefined
}

function getGuessedWord(guess: Guess): string {
  return guess.filter(isString).join('')
}

function loadImage(url: string): Promise<void> {
  return new Promise<void>((resolve) => {
    const image = new Image()
    image.onload = () => {
      resolve()
    }
    // TODO: Handle an image load error.
    image.src = url
  })
}

const thunkSavePuzzleToDb = createAsyncThunk<void, void, { state: RootState }>(
  'thunkSavePuzzleToDb',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState()
    const {
      puzzleNumber,
      guesses,
      guessedLetters,
      invalidGuessIndex,
      currGuessIndex,
      currLetterIndex,
      presentLetters
    } = state.puzzle
    function getGameResult(): GameResult {
      const { gameMode } = state.game
      if (gameMode === 'win' || gameMode === 'lose') {
        return gameMode
      }
    }
    if (puzzleNumber) {
      db.savedPuzzle.put(
        {
          puzzleNumber,
          guesses,
          guessedLetters,
          invalidGuessIndex,
          currGuessIndex,
          currLetterIndex,
          presentLetters,
          gameResult: getGameResult()
        },
        puzzleNumber
      )
    }
  }
)

const thunkLoadPuzzleFromDb = createAsyncThunk<void, void, { state: RootState }>(
  'thunkLoadPuzzleFromDb',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState()

    const { puzzleNumber } = state.puzzle

    const savedPuzzle = await db.savedPuzzle.get({ puzzleNumber })

    thunkAPI.dispatch(setGameMode('active'))

    if (!savedPuzzle) {
      return
    }
    thunkAPI.dispatch(updatePuzzleFromSaved(savedPuzzle))
    if (savedPuzzle.gameResult) {
      thunkAPI.dispatch(setGameMode(savedPuzzle.gameResult))
    }
  }
)

export const thunkFetchPuzzle = createAsyncThunk<void, void, { state: RootState }>(
  'thunkFetchPuzzle',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState()

    if (state.game.gameMode !== 'init') {
      return
    }

    thunkAPI.dispatch(setGameMode('loading'))

    let puzzle: InboundPuzzle | undefined
    try {
      puzzle = await fetchPuzzle()
    } catch (err) {
      let errorMessage = 'ERROR LOADING PUZZLE!'
      if (typeof err === 'number') {
        // status code
        errorMessage = `${errorMessage} (${err})`
      }
      console.error(errorMessage)
      thunkAPI.dispatch(setError(errorMessage))
      return
    }

    thunkAPI.dispatch(setPuzzleNumber(puzzle.puzzleIndex + 1))
    thunkAPI.dispatch(setPicUrls(puzzle.picUrls))
    thunkAPI.dispatch(setValidAnswers(puzzle.validAnswers))
    thunkAPI.dispatch(setArtistStyle(puzzle.artistStyle))
    thunkAPI.dispatch(thunkLoadPuzzleFromDb())
  }
)

const thunkMaybePreloadNextPic = createAsyncThunk<void, number, { state: RootState }>(
  'thunkLoadPicIndex',
  async (index, thunkAPI) => {
    const state = thunkAPI.getState()
    const nextPicIndex = (index + 1) % state.pics.pics.length
    if (!state.pics.pics[nextPicIndex].isLoaded) {
      await loadImage(state.pics.pics[nextPicIndex].url)
      // TODO: Handle an image load error.
      thunkAPI.dispatch(setPicIndexAsLoaded(nextPicIndex))
    }
  }
)

export const thunkLoadPicIndex = createAsyncThunk<void, number, { state: RootState }>(
  'thunkLoadPicIndex',
  async (index, thunkAPI) => {
    const state = thunkAPI.getState()

    if (!state.pics.pics[index].isLoaded) {
      thunkAPI.dispatch(setIsLoadingAPic(true))

      if (isLocalhost()) {
        // add an artificial delay on localhost to make loading clearly visible
        await delay(2000)
      }

      await loadImage(state.pics.pics[index].url)
      // TODO: Handle an image load error.

      thunkAPI.dispatch(setPicIndexAsLoaded(index))
      thunkAPI.dispatch(setIsLoadingAPic(false))
    }

    thunkAPI.dispatch(setCurrPicIndex(index))

    thunkAPI.dispatch(thunkMaybePreloadNextPic(index))
  }
)

export const thunkUnsetInvalidGuessIndex = createAsyncThunk<void, void, { state: RootState }>(
  'thunkUnsetInvalidGuessIndex',
  (_, thunkAPI) => {
    thunkAPI.dispatch(unsetInvalidGuessIndex())
    thunkAPI.dispatch(removeAlertOfType('invalid-word'))
  }
)

export const thunkAddLetter = createAsyncThunk<void, string, { state: RootState }>(
  'thunkAddLetter',
  (letter, thunkAPI) => {
    const state = thunkAPI.getState()

    if (state.game.gameMode !== 'active') {
      return
    }

    if (state.modals.currModalType) {
      return
    }

    thunkAPI.dispatch(addLetter(letter))
    thunkAPI.dispatch(thunkUnsetInvalidGuessIndex())
    thunkAPI.dispatch(thunkSavePuzzleToDb())
  }
)

export const thunkDeleteLetter = createAsyncThunk<void, void, { state: RootState }>(
  'thunkDeleteLetter',
  (_, thunkAPI) => {
    const state = thunkAPI.getState()

    if (state.game.gameMode !== 'active') {
      return
    }

    if (state.modals.currModalType) {
      return
    }

    thunkAPI.dispatch(deleteLetter())
    thunkAPI.dispatch(thunkUnsetInvalidGuessIndex())
    thunkAPI.dispatch(thunkSavePuzzleToDb())
  }
)

export const thunkSubmitGuess = createAsyncThunk<void, void, { state: RootState }>(
  'thunkSubmitGuess',
  (_, thunkAPI) => {
    const state = thunkAPI.getState()

    if (state.game.gameMode !== 'active') {
      return
    }

    if (state.modals.currModalType) {
      return
    }

    if (state.puzzle.currLetterIndex !== WORD_LENGTH) {
      // word is not complete
      return
    }

    const validAnswersLetters = state.puzzle.validAnswersLetters

    if (!validAnswersLetters || !validAnswersLetters.length) {
      console.error('No valid answers when attempting to submit a guess!')
      return
    }

    const guessedWord = getGuessedWord(state.puzzle.guesses[state.puzzle.currGuessIndex])
    if (!ANSWER_WORDS.has(guessedWord) && !ALLOWED_WORDS.has(guessedWord)) {
      thunkAPI.dispatch(setInvalidGuessIndex(state.puzzle.currGuessIndex))
      thunkAPI.dispatch(setAlert({ type: 'invalid-word', text: 'Not in word list' }))
      thunkAPI.dispatch(thunkSavePuzzleToDb())
      return
    }

    const guessedLetters = Array.from(
      new Set([...state.puzzle.guessedLetters, ...state.puzzle.guesses[state.puzzle.currGuessIndex].filter(isString)])
    )
    thunkAPI.dispatch(setGuessedLetters(guessedLetters))

    thunkAPI.dispatch(
      setPresentLetters(guessedLetters.filter((guessedLetter) => validAnswersLetters[0].includes(guessedLetter)))
    )

    const isGuessCorrect = validAnswersLetters.some((validAnswerLetters) =>
      state.puzzle.guesses[state.puzzle.currGuessIndex].every((guessLetter, i) => validAnswerLetters[i] === guessLetter)
    )

    if (isGuessCorrect) {
      thunkAPI.dispatch(showModal({ type: 'win' }))
      thunkAPI.dispatch(setGameMode('win'))
    } else {
      // guess is incorrect
      thunkAPI.dispatch(goToNextGuess())
      if (thunkAPI.getState().puzzle.currGuessIndex === MAX_GUESSES) {
        thunkAPI.dispatch(showModal({ type: 'lose' }))
        thunkAPI.dispatch(setGameMode('lose'))
      }
    }
    thunkAPI.dispatch(thunkSavePuzzleToDb())
  }
)
