import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ColoredGuessText } from '../coloredGuessText'
import { ShareButtonContainer } from '../shareButtonContainer'
import { hideModal } from '../store/slices/modalsSlice'
import { RootState, useAppDispatch } from '../store/store'
import { ArtistStyleDisplay } from './artistStyleDisplay'
import { Modal } from './modal'
import { ResetButtonContainer } from './resetButtonContainer'

export const LoseModal: FC = () => {
  const dispatch = useAppDispatch()

  const validAnswersLetters = useSelector((state: RootState) => state.puzzle.validAnswersLetters)

  return (
    <Modal
      title="You Didn't Get It!"
      body={
        <>
          ...but you do look nice today.
          {validAnswersLetters && !!validAnswersLetters.length && (
            <div className='p-5 text-center'>
              <ColoredGuessText
                label={validAnswersLetters[0].join('')}
                color='red'
                isLarge={true}
                isSegmented={true}
                isSelectable={false}
              />
            </div>
          )}
          <br />
          <div className='p-5 text-center'>
            <ShareButtonContainer />
          </div>
          <ArtistStyleDisplay />
          <br />
          {/* TODO: Add countdown */}
          Come back and play again!
          <br />
          New puzzles coming soon!
          <ResetButtonContainer />
        </>
      }
      onClickClose={() => {
        dispatch(hideModal())
      }}
    />
  )
}
