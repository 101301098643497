import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { BlinkingCursor } from '../blinkingCursor'
import { WORD_LENGTH } from '../constants'
import { RootState } from '../store/store'

const SIZE = 40

type GuessLetterMode = 'default' | 'absent' | 'present' | 'invalid' | 'winning'

export const GUESS_LETTER_MODE_CLASS_NAMES: Record<GuessLetterMode, string> = {
  default: 'text-white bg-black',
  absent: 'text-gray-700 bg-gray-500',
  present: 'text-black bg-sky-600',
  invalid: 'text-red-600 bg-black',
  winning: 'text-black bg-emerald-300'
}

interface Props {
  guessIndex: number
  letterIndex: number
}

export const GuessLetterDisplay: FC<Props> = ({ guessIndex, letterIndex }: Props) => {
  const letter = useSelector((state: RootState) => state.puzzle.guesses[guessIndex][letterIndex])
  const currGuessIndex = useSelector((state: RootState) => state.puzzle.currGuessIndex)
  const currLetterIndex = useSelector((state: RootState) => state.puzzle.currLetterIndex)
  const presentLetters = useSelector((state: RootState) => state.puzzle.presentLetters)
  const isActiveGame = useSelector((state: RootState) => state.game.gameMode === 'active')
  const isGameWon = useSelector((state: RootState) => state.game.gameMode === 'win')
  const hasModal = useSelector((state: RootState) => !!state.modals.currModalType)
  const isInvalidGuess = useSelector((state: RootState) => state.puzzle.invalidGuessIndex === guessIndex)

  const isCurrentCursor =
    isActiveGame &&
    currGuessIndex === guessIndex &&
    (currLetterIndex === letterIndex || (currLetterIndex === WORD_LENGTH && letterIndex === WORD_LENGTH - 1))
  const showBlinkingCursor = isActiveGame && !hasModal && isCurrentCursor

  function getGuessLetterMode(): GuessLetterMode {
    if (letter) {
      if (isInvalidGuess) {
        return 'invalid'
      }
      if (guessIndex < currGuessIndex || !isActiveGame) {
        // completed guess, show hints
        if (isGameWon && guessIndex === currGuessIndex) {
          // game is won, and this is the winning guess in the grid
          // (when the game is won, currGuessIndex does not increment past the winning guess)
          return 'winning'
        }
        return presentLetters.includes(letter) ? 'present' : 'absent'
      }
    }
    return 'default'
  }

  return (
    <div
      className={`flex flex-col items-center justify-center relative text-4xl rounded-lg text-center font-bold ${
        GUESS_LETTER_MODE_CLASS_NAMES[getGuessLetterMode()]
      }`}
      style={{
        padding: '30px',
        width: `${SIZE}px`,
        maxWidth: `${SIZE}px`,
        height: `${SIZE}px`,
        maxHeight: `${SIZE}px`
      }}
      data-guess-index={guessIndex}
      data-letter-index={letterIndex}
    >
      {showBlinkingCursor && <BlinkingCursor />}
      {letter}
    </div>
  )
}
