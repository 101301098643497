import React, { FC } from 'react'

import { db } from '../db'
import { Button } from '../button'

export const ResetButtonContainer: FC = () => {
  const onClick = async () => {
    await db.delete()
    window.location.reload()
  }

  return (
    <div>
      <Button label='RESET' onClick={onClick} isLarge={false} isInverted={false} />
    </div>
  )
}
