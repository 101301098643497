import React, { FC, ReactNode } from 'react'

import { Button } from '../button'
import { ModalCloseButton } from './modalCloseButton'

interface ModalButton {
  label: string
  onClick: () => void
}

interface Props {
  title: string
  body: ReactNode
  modalButtons?: ModalButton[]
  onClickClose: () => void
}

// Adapted from https://tailwindui.com/components/application-ui/overlays/modals
export const Modal: FC<Props> = ({ title, body, modalButtons, onClickClose }: Props) => {
  return (
    <div className='relative z-10 select-text' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

      <div className='fixed inset-0 z-10 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all m-8'>
            <div className='bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative'>
              <div className='sm:flex sm:items-start'>
                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <div className='flex flex-row items-center'>
                    <h3 className='grow text-6xl font-bold leading-6 text-white' id='modal-title'>
                      {title}
                    </h3>
                    <ModalCloseButton onPointerDown={onClickClose} />
                  </div>
                  <div className='mt-2'>
                    <div className='text-4xl text-gray-500'>{body}</div>
                  </div>
                </div>
              </div>
            </div>
            {modalButtons?.length && (
              <div className='bg-gray-800 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                {modalButtons.map(({ label, onClick }, i) => (
                  <Button key={i} label={label} onClick={onClick} isLarge={true} isInverted={false} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
