import React, { FC } from 'react'

interface Props {
  label: string
  onClick: () => void
}

export const HeaderButton: FC<Props> = ({ label, onClick }: Props) => {
  return (
    <button className='bg-white p-1 rounded-lg text-black hover:bg-gray-400' onClick={onClick}>
      {label}
    </button>
  )
}
