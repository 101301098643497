import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { removeAlert } from './store/slices/errorSlice'

import { RootState, useAppDispatch } from './store/store'

export const AlertDisplayContainer: FC = () => {
  const alert = useSelector((state: RootState) => state.error.alert)

  const dispatch = useAppDispatch()

  if (!alert) {
    return null
  }

  const onClick = () => {
    dispatch(removeAlert())
  }

  return (
    <section
      aria-label='alert'
      className='fixed top-0 bg-gray-900 z-30 text-4xl text-center p-3 font-bold cursor-pointer rounded-b-xl text-red-600'
      onClick={onClick}
    >
      {alert.text}
    </section>
  )
}
